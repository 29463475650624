<template>
  <div class="page-dds">
    <van-nav-bar title="待定损多单收款--修改" left-arrow :fixed="true" :z-index="999" @click-left="back"></van-nav-bar>
    <div class="nav-bar">
      <van-row class="topss">
        <div class="flex-wrap" style="margin-bottom:10px;">
          <div class="left">
            <span>待定方：</span>
            <el-select v-model="baseObj.ddsName" placeholder="请选择">
              <el-option v-for="(item,index) in inOptions" :key="index" :label="item" :value="item"> </el-option>
            </el-select>
          </div>
          <div class="left">
            <span>收款日期：</span>
            <span class="date" @click="timeshow=true">{{baseObj.bizDate}}</span>
          </div>
        </div>
        <van-row class="tl">
          <van-col span="5" class="tl">支付方式：</van-col>
          <van-col span="19" class="tl">
            <el-select style="width: 130px" v-model="baseObj.payType" size="mini" default-first-option placeholder="请选择">
              <el-option v-for="(item, iss) in columns" :key="iss" :label="item.typeStr" :value="item.id"></el-option>
            </el-select>
          </van-col>
          <van-col span="5">收款金额：</van-col>
          <van-col span="8" class="cRed bold font_14">￥{{ baseObj.total }}</van-col>
          <van-col span="4" v-if="id">录单人：</van-col>
          <van-col span="7" v-if="id">{{ baseObj.recordName }}</van-col>
        </van-row>

        <div class="fkpz">
          <div style="padding-top: 10px;">
            <div>收款截图<br />(最多3张)</div>
          </div>
          <div class="imgdiv" v-for="(item, is) in picDOS" :key="is">
            <img class="pzimgs" @click="toimgdan(item)" :src="item" alt="">
            <img class="scimgs" @click="dltimgs(item, is)" src="../../../assets/img/guan2.png" alt="">
          </div>
          <van-uploader :after-read="onReadfkm" v-if="picDOS.length<3">
            <div class="scdiv">
              <van-icon name="plus" />
            </div>
          </van-uploader>
        </div>
      </van-row>
      <van-row class="mian_row" style="font-weight: bold;border-top:1px solid #f5f5f5;">
        <van-col span="1">#</van-col>
        <van-col span="5">车牌号</van-col>
        <van-col span="5">保险公司</van-col>
        <van-col span="4">定损金额</van-col>
        <van-col span="4">服务费</van-col>
        <van-col span="4">到账金额</van-col>
        <van-col span="1" @click="onCar"><van-icon color="red" name="plus" size="16" /></van-col>
      </van-row>
    </div>
    <div class="pt" v-if="list.length > 0">
      <van-list v-model="loading" :finished="finished" :immediate-check="false" finished-text="没有更多了" @load="onLoads" :offset="10">
        <div class="mian" v-for="(item, idx) in list" :key="idx">
          <van-row class="mian_row" @click="tourls(item)">
            <van-col span="1">{{ idx }}</van-col>
            <van-col span="5" class="font_12 slh">{{ item.carNo }}</van-col>
            <van-col span="5" class="slh">{{ item.insureCompany }}</van-col>
            <van-col span="4">{{ item.dsAmount }}</van-col>
            <van-col span="4">{{ item.serviceAmount }}</van-col>
            <van-col span="4">{{item.accountAmount}}</van-col>
            <van-col span="1" class="tr" @click="deleteFun(item,idx)"><van-icon color="red" name="delete" size="16" /></van-col>
          </van-row>
        </div>
      </van-list>
    </div>
    <van-empty v-else description="暂无数据~" />
    <div class="botbox flex">
      <van-button type="danger" @click="zfFun">作废</van-button>
      <van-button type="primary" @click="confirmFun">确认修改</van-button>
    </div>
    <van-popup :close-on-click-overlay="false" v-model="timeshow" position="bottom" :style="{ height: '45%' }">
      <van-datetime-picker @cancel="timeshow = false" @confirm="setTime" v-model="currentDate" type="date" title="请选择日期" :formatter="formatter" />
    </van-popup>

    <van-popup :close-on-click-overlay="false" v-model="addShow" position="top" :style="{ height: '100%' }">
      <van-nav-bar title="添加事故车收款" left-arrow :fixed="true" :z-index="999" @click-left="offAdd"></van-nav-bar>
      <div class="nav-bar">
        <el-form ref="form" :model="form" :rules="rules" class="formbox" label-width="80px">
          <el-form-item label="车辆号码">
            <el-input v-model="form.carNo" clearable></el-input>&nbsp;&nbsp;
            <el-button type="danger" icon="el-icon-search" @click="getaccidentFun">搜索</el-button>
          </el-form-item>
          <!-- <el-form-item label="工单号">
            <el-input disabled v-model="form.oid"></el-input>
          </el-form-item> -->
          <el-form-item label="定损金额">
            <el-input v-model="form.dsAmount" type="number" clearable @blur="blurFun(form.dsAmount)" ></el-input>
          </el-form-item>
          <el-form-item label="实收金额">
            <el-input v-model="form.accountAmount" type="number" clearable @blur="blurFun(form.accountAmount)"></el-input>
          </el-form-item>
        </el-form>
        <div class="botbox flex">
          <van-button type="danger" @click="addConfim">添加</van-button>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="carShow" style="width: 90%; min-height: 200px; max-height: 80%; position: fixed; margin-top: 30px; ">
      <van-icon class="close" name="cross" @click="offCar"></van-icon>
      <div class="tc flex-center" style="width: 100%; height: 30px; font-weight: bold; margin-top: 10px">
        <div class="bold tc flex1">选择车辆</div>
      </div>
      <van-radio-group v-model="radioValue" style="max-height: 400px; overflow-y: scroll">
        <van-cell-group>
          <van-cell :title="item.carNo+'  '+item.insureName+'  '+item.billNumber" clickable @click="carChange(item)" v-for="(item, index) in carList" :key="index">
            <van-radio :name="item.carNo" />
          </van-cell>
        </van-cell-group>
      </van-radio-group>
      <div class="empty-box cText" v-if="carList.length < 1">
        暂无数据~
      </div>
    </van-popup>
  </div>
</template>

<script>
import { accident,insuranceList,uploadPicture,ddsDetail,ddsAdd,ddsZf,getpay } from "@/api/check";
import moment from "moment";
export default {
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // 通过 `vm` 访问组件实例
      // vm.previousRoute = from.path;
      if(from.path == '/pending'){
        vm.id=vm.$route.query.id
        vm.initData()
        vm.getInsuranceListFun()
      }
    });
  },
  data() {
    return {
      gid: this.$route.query.gid,
      id: this.$route.query.id,
      key: "",
      loading: false,
      total: 0,
      finished: false,
      isiOS: false,
      currentDate: new Date(),
      timeshow:false,
      columns:[],
      baseObj:{
        ddsName:"", //待定方
        bizDate:moment(new Date()).format("YYYY-MM-DD"), //收款日期
        total:"", //总金额
        recordName:"", //录单人
        ddsAccountId:null
      },
      form:{
        carNo:"",
        dsAmount:"",
        accountAmount:"",
        ddsAccountId:null,
        gid:this.$route.query.gid,
        insureCompany:"",
        serviceAmount:""
      },
      picDOS:[
        // "http://vehicle-repairs.oss-cn-hangzhou.aliyuncs.com/test/999/173149414251201.jpeg?Expires=2046854142&OSSAccessKeyId=LTAI4FhBMt2eaMxuMMSCECEm&Signature=em5CEeN99yF9xeptA0zaMmODVKo%3D",
        // "http://vehicle-repairs.oss-cn-hangzhou.aliyuncs.com/test/999/173149414251201.jpeg?Expires=2046854142&OSSAccessKeyId=LTAI4FhBMt2eaMxuMMSCECEm&Signature=em5CEeN99yF9xeptA0zaMmODVKo%3D",
        // "http://vehicle-repairs.oss-cn-hangzhou.aliyuncs.com/test/999/173149414251201.jpeg?Expires=2046854142&OSSAccessKeyId=LTAI4FhBMt2eaMxuMMSCECEm&Signature=em5CEeN99yF9xeptA0zaMmODVKo%3D",
      ],
      list: [
        // {
        //   "accountAmount": 0,
        //   "carNo": "粤123456",
        //   "createdBy": 0,
        //   "createdDate": "",
        //   "ddsAccountId": 0,
        //   "dsAmount": 0,
        //   "gid": 0,
        //   "id": 0,
        //   "insureCompany": "深圳曹总",
        //   "lastModifiedBy": 0,
        //   "lastModifiedDate": "",
        //   "oid": 0,
        //   "serviceAmount": 0
        // },
      ],
      addShow:false,
      carShow:false,
      inOptions:[],
      carList:[],
      radioValue:"",
      rules: {
        carNo: [{ required: true, message: '请输入车牌号码', trigger: 'blur' }],
        dsAmount: [{ required: true, message: '请输入定损金额', trigger: 'blur' },{ type: 'number', message: '金额必须为整数'}],
        accountAmount: [{ required: true, message: '请输入实收金额', trigger: 'blur' },{ type: 'number', message: '金额必须为整数'}],
      }
    };
  },
  methods: {
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      } else if (type === "day") {
        return `${val}日`;
      }
      return val;
    },
    back() {
      this.$router.go(-1);
    },
    offAdd(){
      this.addShow = false
    },
    onCar(){
      this.addShow = true
      this.emptyObj()
    },
    offCar(){
      this.carShow = false
    },
    emptyObj(){
      Object.keys(this.form).forEach(key => {
        this.form[key] = null; 
      });
      // for (let key in this.form) {
      //   if (this.form.hasOwnProperty(key)) {
      //     this.form[key] = null; 
      //   }
      // }
    },
    carChange(item){
      this.radioValue = item
      this.carShow = false
      this.form = {...this.form,...item}
    },
    blurFun(){
      this.form.dsAmount = this.form.dsAmount?parseInt(this.form.dsAmount, 10):""
      this.form.accountAmount = this.form.accountAmount?parseInt(this.form.accountAmount, 10):""
    },
    addConfim(){
      if(!this.form.carNo){
        this.$toast("请输入车牌号码");
        return;
      }
      if(!this.form.dsAmount){
        this.$toast("请输入定损金额");
        return;
      }
      if(!this.form.accountAmount){
        this.$toast("请输入实收金额");
        return;
      }
      this.form.gid = this.gid
      // 计算服务费
      let num = this.form.dsAmount - this.form.accountAmount
      this.form.serviceAmount = num>=0 ? num : 0

      // 保险公司
      this.form.insureCompany = this.form.insureName
      // console.log('form===',this.form)
      // let objCopy = _.cloneDeep(this.form);  //用loadash插件计算
      let objCopy = JSON.parse(JSON.stringify(this.form))
      this.list.push(objCopy)
      this.countFun()
      this.addShow = false
    },
    countFun(){
      this.baseObj.total = this.list.reduce((accumulator, currentItem) => {
        return accumulator + currentItem.accountAmount;
      }, 0);
    },
    zfFun(){
      ddsZf({ddsAccountId:this.id}).then(res=>{
        if(res.code == 200){
          this.$toast("已作废!");
          this.back()
        }else{
          this.$toast(res.message);
        }
      })
    },
    confirmFun(){
      this.countFun() //计算总额
      let params = {
        ...this.baseObj,
        gid:this.gid,
        pics:this.picDOS,
        counts:this.list.length,
        details:this.list
      }
      params.bizDate = params.bizDate+" 00:00:00"
      if(!params.ddsName){
        this.$toast("请选择待定损方!");
        return;
      }
      if(params.counts<1){
        this.$toast("请选择车辆!");
        return;
      }
      ddsAdd(params).then(res=>{
        if(res.code == 200){
          this.$toast("修改成功!");
          this.back()
        }else{
          this.$toast(res.message);
        }
      })
    },

    setTime(e) {
      this.baseObj.bizDate = moment(e).format("YYYY-MM-DD");
      this.timeshow = false;
    },
    async onReadfkm(file) {
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "上传中...",
      });
      let files = this.dataURLtoFile(file.content, file.file.name);
      let formdata1 = new FormData();
      formdata1.append("file", files);
      uploadPicture(formdata1).then((e) => {
        loading.clear();
        if (e.code == 200) {
          this.picDOS.push(e.data)
          // this.setaddpic(e.data);
        }
      });
    },

    dataURLtoFile(dataurl, filename) {
      // 将base64转换为file文件
      let arr = dataurl.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {
        type: mime,
      });
    },
    toimgdan(img) {
      this.$router.push({
        name: "showimg",
        query: {
          img: img,
          idx: 0,
        },
      });
    },
    dltimgs(item, idx) {
      this.picDOS.splice(idx, 1);
      this.$toast("删除成功");
    },
    deleteFun(item,idx){
      this.list.splice(idx, 1);
      this.countFun()
    },
    // 查询待定损方
    getInsuranceListFun(){
      insuranceList({gid:this.gid}).then(res=>{
        if(res.code == 200){
          this.inOptions = res.data
        }
      })
    },
    // 查询车辆列表
    getaccidentFun(){
      this.carShow = true
      accident({gid:this.gid,carNo:this.form.carNo?this.form.carNo:""}).then(res=>{
        if(res.code == 200){
          let arr = res.data || []
          // this.carList = res.data
          // 过滤掉已经选择过的车辆
          this.carList = arr.filter(item2 => 
            !this.list.some(item1 => item1.carNo === item2.carNo)
          );
        }
      })
    },
    getpayFun() {
      getpay({ gid: this.gid }).then(res => {
        if (res.code == 200) {
          this.columns = res.data
        }
      })
    },
    onLoads(){
      this.initData()
      this.getInsuranceListFun()
    },

    initData(){
      let params = {
        ddsAccountId:this.id
      }
      ddsDetail(params).then(res=>{
        if(res.code == 200){
          this.baseObj = res.data.ddsAccountDO
          this.baseObj.ddsAccountId = res.data.ddsAccountId
          this.baseObj.bizDate = this.baseObj.bizDate?this.baseObj.bizDate.substring(0,10):""
          this.list = res.data.detailsDOS || []
          this.picDOS = res.data.picDOS || []
        }
      })
    }
  },
  created() {
    this.getpayFun()
    this.initData()
    this.getInsuranceListFun()
    // this.getaccidentFun()
  },
  mounted() {
  },
  computed: {},
};
</script>

<style lang="less" scoped>
.page-dds{
  background: #f6f6f6;
  color: #000;
  font-size: 14px;
  .el-select{
    max-width: 100px;
  }
}
.nav-bar {
  position: sticky;
  top: 46px;
  left: 0;
  width: 100%;
  z-index: 99;
  background: #fff;
}

::v-deep .topss {
  padding: 10px;
  background: #fff;
  .el-input__inner{
   height: 24px;
   font-size: 13px;
  }
  .el-input__suffix{
    margin-top: 8px;
  }
  .date{
    border: 1px solid #eee;
    padding: 4px 6px;
    border-radius: 4px;
  }
}

.red {
  color: red;
  font-weight: bold;
}

.hedt {
  line-height: 35px;
  font-weight: bold;
  font-size: 15px;
  text-align: center;
  padding: 0 10px;
  background: #fff;
  // padding-top: 46px;
}

.flx {
  display: flex;
  align-items: center;
}

.pt {
  padding-top: 46px;
}

.mian {
  border-top: 1px solid #f5f5f5;
  border-radius: 6px;
  padding: 5px 0;
  background: #fff;
}


.bot {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 50px;

  .van-button {
    width: 94%;
    margin: auto;
    display: block;
    font-size: 15px;
    height: 42px;
  }
}

.mian_row {
  line-height: 35px;
  font-size: 14px;
  padding: 0 10px;
  background: #fff;
  .van-col{
    text-align: center;
  }
}
.fkpz {
  display: flex;
  margin-top: 5px;
  background: #fff;
  text-align: center;
  border-radius: 4px;
  //padding: 0 15px;
  font-size: 14px;
  height: 80px;
  line-height: 16px;
  .scdiv {
    width: 50px;
    height: 70px;
    border: 1px solid #dcdcdc;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

  }
  .imgdiv {
    width: 60px;
    height: 70px;
    margin-left: 10px;
    position: relative;

    .pzimgs {
      width: 100%;
      height: 70px;
    }
    .scimgs {
      width: 24px;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 9;
    }
  }
}
.botbox {
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  background: #fff;
  z-index: 9;
  .van-button{
    flex: 1;
  }
}
.formbox{
  padding-top: 20px;
  .el-input{
    width: 150px;
  }
  .el-button--primary {
    border-color: #07c160;
    background-color: #07c160;
  }
}
.close {
  text-align: right;
  position: absolute;
  right: 10px;
  top: 10px;
}

.van-icon-checked:before {
  content: "";
  width: 10px;
  height: 10px;
  background: #07c160;
  /* background: #356316; */
  border-radius: 50%;
}

.van-step--finish {
  color: #07c160;
}
.van-cell__value{
  flex: inherit;
}
::v-deep .el-input__icon{
  line-height: 1.06667rem;
}
</style>